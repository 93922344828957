import UseDocumentTitle from "../../../components/__Shared/UseDocumentTitle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import FormSchema from "./FormSchema";
import { AuthContext } from "../../../providers/AuthProvider";
import getAuthorizedApi from "../../../providers/api";
import Modal from "../../../components/__Shared/Modal";

import InputField from "../__Shared/Form/InputField";
import RadioField from "../__Shared/Form/RadioField";
import SelectField from "../__Shared/Form/SelectField";
import TextareaField from "../__Shared/Form/TextareaField";
import debounce from "../../../utils/Debounce";
import Comm from "../../../utils/Comm";
import {
  LEOS_PRICE,
  LEOS_SALES_DATA,
  LEOS_TREASURY_DATA,
} from "../../../utils/GetLeosAmount";

export default function AddUser() {
  UseDocumentTitle("Add User - LEOS Platform");

  const { user } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [leosURL, setLeosURL] = useState(null);

  const [usd, setUSD] = useState(null);

  const {
    register,
    unregister,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
  });

  const watchBusinessPerson = watch("businessOrPerson");
  const watchIssuedLeos = watch("leosIssued") === "true" ? true : false;
  const watchPaymentMethod = watch("paymentMethod");
  const watchLeosAmount = watch("leosAmount");
  const watchCurrency = watch("currency");
  const watchAmount = watch("amount");

  useEffect(() => {
    if (watchBusinessPerson === "business") {
      register("chamberOfCommerceNumber");
      register("registeredAddress");
      register("companyName");
      register("vatNumber");
      unregister("firstName");
      unregister("lastName");
    } else if (watchBusinessPerson === "person") {
      register("firstName");
      register("lastName");
      unregister("chamberOfCommerceNumber");
      unregister("registeredAddress");
      unregister("companyName");
      unregister("vatNumber");
    }
    if (watchIssuedLeos) {
      register("transactionId");
      unregister("seedRound");
      unregister("treasury");
    } else {
      unregister("transactionId");
      register("seedRound");
      register("treasury");
    }

    if (watchPaymentMethod === "metamask") {
      register("transactionHash");
      unregister("paymentReference");
    } else {
      unregister("transactionHash");
      register("paymentReference");
    }
  }, [
    register,
    unregister,
    watchBusinessPerson,
    watchIssuedLeos,
    watchPaymentMethod,
  ]);

  const onSubmit = async (data) => {
    setLeosURL(null);
    setLoading(true);
    try {
      await getAuthorizedApi().checkAccountName(data.accountName);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      setError(
        "The United Citizens Wallet account name cannot be found. Please enter a valid name."
      );
      return;
    }
    data = {
      ...data,
      username: data.username || "",
      amount: data.amount.toString(),
      leosAmount: data.leosAmount.toFixed(4),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
    };
    try {
      const result = await getAuthorizedApi(user).addPayment(data);
      setLeosURL(result.transactionUrl);
      setLoading(false);
      reset();
      setShowSuccess(true);
      setUSD(null);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      setError(error.response.data.message);
    }
  };

  const requestCrypto = useMemo(() => {
    return debounce((amount) => {
      const currency = watch("currency") || "ETH";
      let ethConfig = {
        method: "get",
        url: `/crypto?symbol=${currency}&amount=${amount}`,
        headers: {},
      };
      Comm.request(ethConfig).then((response) => {
        setUSD(response.data.usd);
      });
    }, 500);
  }, [watch]);

  const handleChangeAmount = useCallback(
    (amount) => {
      requestCrypto(amount);
    },
    [requestCrypto]
  );

  useEffect(() => {
    if (Number(watchAmount) !== 0 && !isNaN(Number(watchAmount))) {
      handleChangeAmount(watchAmount);
    }
  }, [watchAmount, watchCurrency, handleChangeAmount]);

  const seedRoundOptions = LEOS_SALES_DATA.map((round) => ({
    value: round.saleRound,
    label: `Round: ${round.saleRound} (${round.seed})`,
  }));

  const treasuryOptions = LEOS_TREASURY_DATA.map((round) => ({
    value: round,
    label: round,
  }));

  return (
    <div className="position-relative content-detail-block flex-grow-1">
      {loading === true && (
        <div className="loader-container" style={{ position: "fixed" }}>
          <span className="loader"></span>
        </div>
      )}
      <Modal isOpen={showError} onClose={() => setShowError(false)}>
        <Modal.Header>
          <h3 className="model-title">Server Error</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="mt-2">{error}</p>
          <button
            onClick={() => setShowError(false)}
            className="btn btn-lg btn-secondary w-100 mt-4"
          >
            <span>Please try again</span>
          </button>
        </Modal.Body>
      </Modal>
      <Modal isOpen={showSuccess} onClose={() => setShowSuccess(false)}>
        <Modal.Header>
          <h3 className="model-title text-success">Payment Successful</h3>
        </Modal.Header>
        <Modal.Body>
          <p>Payment entry has been successfully inserted.</p>
          <div className="d-flex gap-2 mt-4">
            {leosURL && (
              <a
                target="_blank"
                href={leosURL}
                rel="noreferrer"
                className="btn btn-lg btn-success mt-3"
              >
                Goto LEOS issued URL
              </a>
            )}
            <button
              onClick={() => setShowSuccess(false)}
              className="btn btn-lg btn-outline mt-3"
            >
              <span>Close</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="row">
        <div className="col-12 col-md-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend className="fs-5 fw-medium text-uppercase">
                Business or Person?
              </legend>
              <RadioField
                label=""
                name="businessOrPerson"
                id="businessOrPerson"
                options={[
                  { label: "Business", value: "business" },
                  { label: "Person", value: "person" },
                ]}
                register={register}
                errors={errors}
              />

              {watchBusinessPerson === "business" && (
                <div className="mb-3">
                  <div className="mb-3">
                    <InputField
                      label="Chamber of Commerce Number"
                      name="chamberOfCommerceNumber"
                      id="chamberOfCommerceNumber"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="mb-3">
                    <InputField
                      label="Registered Address"
                      name="registeredAddress"
                      id="registeredAddress"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="mb-3">
                    <InputField
                      label="Company Name"
                      name="companyName"
                      id="companyName"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <InputField
                    label="Vat Number"
                    name="vatNumber"
                    id="vatNumber"
                    register={register}
                    errors={errors}
                  />
                </div>
              )}
              {watchBusinessPerson === "person" && (
                <div className="mb-3">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <InputField
                        label="First Name"
                        name="firstName"
                        id="firstName"
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <InputField
                        label="Last Name"
                        name="lastName"
                        id="lastName"
                        register={register}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <hr />
            <fieldset>
              <legend className="fs-5 fw-medium text-uppercase">
                Marketing Email Consent Details
              </legend>
              <InputField
                label="Email"
                name="email"
                id="email"
                register={register}
                errors={errors}
              />
              <div className="row mt-3">
                <div className="col-12 col-md-3">
                  <RadioField
                    label="Email Consent"
                    name="marketingEmailConsent"
                    id="marketingEmailConsent"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="col-12 col-md-9">
                  <InputField
                    label="Referral Code"
                    name="referralCode"
                    id="referralCode"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
            </fieldset>
            <hr />
            <fieldset>
              <legend className="fs-5 fw-medium text-uppercase">
                United Citizens Wallet Details
              </legend>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="accountName" className="form-label">
                      Account name
                    </label>
                    <input
                      {...register("accountName")}
                      type="text"
                      className={`form-control ${
                        errors.accountName ? "is-invalid" : ""
                      }`}
                      id="accountName"
                    />
                    {errors.accountName && (
                      <div className="invalid-feedback d-block">
                        {errors.accountName.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      {...register("username")}
                      type="text"
                      className={`form-control ${
                        errors.username ? "is-invalid" : ""
                      }`}
                      id="username"
                    />
                    {errors.username && (
                      <div className="invalid-feedback d-block">
                        {errors.username.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </fieldset>
            <hr />
            <fieldset>
              <legend className="fs-5 fw-medium text-uppercase">
                Token Agreement Details
              </legend>
              <div className="mb-3">
                <label htmlFor="docusealUrl" className="form-label">
                  Docuseal Url
                </label>
                <input
                  {...register("docusealUrl")}
                  type="text"
                  className={`form-control ${
                    errors.docusealUrl ? "is-invalid" : ""
                  }`}
                  id="docusealUrl"
                  placeholder="https://docuseal.eu/submissions/{submissionId}"
                />
                {errors.docusealUrl && (
                  <div className="invalid-feedback d-block">
                    {errors.docusealUrl.message}
                  </div>
                )}
              </div>
            </fieldset>
            <hr />
            <fieldset>
              <legend className="fs-5 fw-medium text-uppercase">
                Payment Details
              </legend>
              <div className="row">
                <div className="col-12 col-md-6">
                  <SelectField
                    label="Payment Method"
                    name="paymentMethod"
                    id="paymentMethod"
                    options={[
                      { label: "Metamask", value: "metamask" },
                      { label: "SEPA", value: "SEPA" },
                      { label: "Other", value: "other" },
                    ]}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    label="Payment Date"
                    name="paymentDate"
                    id="paymentDate"
                    register={register}
                    errors={errors}
                    type="datetime-local"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-8">
                  <InputField
                    label="Amount"
                    name="amount"
                    id="amount"
                    register={register}
                    errors={errors}
                    onChange={handleChangeAmount}
                  />
                  {usd && (
                    <div className="d-block valid-feedback mb-2">${usd}</div>
                  )}
                </div>
                <div className="col-12 col-md-4">
                  <SelectField
                    label="Currency"
                    name="currency"
                    id="currency"
                    options={[
                      { label: "ETH", value: "ETH" },
                      { label: "EUR", value: "EUR" },
                      { label: "USD", value: "USD" },
                      { label: "USDT", value: "USDT" },
                    ]}
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="mb-3">
                <InputField
                  label="LEOS Amount"
                  name="leosAmount"
                  id="leosAmount"
                  register={register}
                  errors={errors}
                />
                {watchLeosAmount && (
                  <div className="d-block valid-feedback ">
                    ${`${(watchLeosAmount * LEOS_PRICE).toFixed(4)}`}
                  </div>
                )}
              </div>

              {watchPaymentMethod === "metamask" && (
                <div className="mb-3">
                  <InputField
                    label="Metamask Transaction Hash"
                    name="transactionHash"
                    id="transactionHash"
                    placeholder="0x26f98fdee817f6ee9c87a2cef9bd92a18e1e2904fc1cb7d7d8afa2187e36fa4c"
                    register={register}
                    errors={errors}
                  />
                </div>
              )}

              {watchPaymentMethod !== "metamask" && (
                <TextareaField
                  label="Payment Reference"
                  name="paymentReference"
                  id="paymentReference"
                  register={register}
                  errors={errors}
                />
              )}

              <div className="mt-3">
                <RadioField
                  label="LEOS issued already?"
                  name="leosIssued"
                  id="leosIssued"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  defa
                  register={register}
                  errors={errors}
                />
              </div>

              {watchIssuedLeos && (
                <InputField
                  label="Issued Token Transaction ID"
                  name="transactionId"
                  id="transactionId"
                  register={register}
                  errors={errors}
                  placeholder="abcd1234efgh5678ijkl9012mnop3456qrst7890uvwx1234yzab5678cdef9012"
                />
              )}

              {watch("leosIssued") === "false" && (
                <div className="row">
                  <div className="col-12 col-md-5">
                    <SelectField
                      label="Seed Round"
                      name="seedRound"
                      id="seedRound"
                      options={seedRoundOptions}
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12 col-md-7">
                    <SelectField
                      label="Treasury"
                      name="treasury"
                      id="treasury"
                      options={treasuryOptions}
                      register={register}
                      errors={errors}
                      defaultValue={treasuryOptions[0]?.value}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            <hr />
            <div className="mt-4">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary btn-lg px-5"
              >
                Add Manual Payment
              </button>
              <button
                onClick={() => reset()}
                type="button"
                className="btn btn-light btn-lg ms-2"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
