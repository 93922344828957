export const LEOS_PRICE = 0.0004;

export const LEOS_SALES_DATA = [
  {
    saleRound: 1,
    seed: "Seed",
    price: 0.0002,
    saleRoundSupply: 3500000000,
    category: 8,
  },
  {
    saleRound: 2,
    seed: "Pre-sale",
    price: 0.0004,
    saleRoundSupply: 4000000000,
    category: 9,
  },
  {
    saleRound: 3,
    seed: "Public",
    price: 0.0012,
    saleRoundSupply: 5000000000,
    category: 10,
  },
];

export const LEOS_TREASURY_DATA = [
  "coinsale.tmy",
  "team.tmy",
  "legal.tmy",
  "reserves.tmy",
  "partners.tmy",
  "liquidty.tmy",
  "marketng.tmy",
  "ops.tmy",
  "infra.tmy",
  "ecosystm.tmy",
];
